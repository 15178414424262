import { useState } from 'react';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  getProductCanUpsellPrevent,
  hasCustomPlanDoc,
  useProductsByState,
} from '@pumpkincare/plans';
import { PET_AGE_OPTIONS } from '@pumpkincare/shared';
import {
  ButtonStyles,
  ChevronRight,
  Heart,
  LegalBody,
  Spinner,
  Typography,
} from '@pumpkincare/shared/ui';
import {
  getPetStatusCopy,
  getPlanDates,
  getPlanDocuments,
  getPlanEntitlements,
  getPlanPrice,
  getPolicyConfig,
  getPolicyDates,
  getPolicyDocuments,
  getPolicyPrice,
  getPolicyReimbursementPercentage,
  getPolicyUtilization,
  getUserIsChargedAnnually,
  getUserRatingAddress,
  getUserTransactionFee,
  getWellnessDates,
  getWellnessDocuments,
  getWellnessEntitlements,
  getWellnessPrice,
  PetPlanOverview,
  PetPolicyOverview,
  PetWellnessOverview,
  useUserAddresses,
  useUssr,
} from '@pumpkincare/user';

import { MemberCallout } from '../../../../shared';
import usePetFromRoute from '../../../hooks/use-pet-from-route';
import usePetPolicyConfig from '../../../hooks/use-pet-policy-config';
import { downloadPlanCa, downloadPolicyCa } from '../../../utils';
import RenewalModal from '../../renewal-modal';
import WellnessUpsell from '../wellness-upsell';

import styles from './pet-coverage.css';

function formatPetAge(age) {
  if (!age) return '';

  const ageLabel = PET_AGE_OPTIONS.find(option => option.value === age)?.label;

  return ageLabel.replace(/ old/, '').replace(/year/, 'Year');
}

function PetCoverage() {
  const { punks1928WellnessEmployerBenefit } = useFlags();
  const { data: userData } = useUssr();
  const isAnnuallyCharged = getUserIsChargedAnnually(userData);
  const transactionFee = getUserTransactionFee(userData);
  const { pet } = usePetFromRoute();
  const shouldShowWellnessUpsell = !pet.wellness.length && !pet.plans.length;
  const shouldShowInsuranceUpsell =
    punks1928WellnessEmployerBenefit && !pet.policies.length;

  const { data: addressData } = useUserAddresses();
  const ratingData = getUserRatingAddress(addressData);

  const { data: productsByStateData, isFetching: isProductsFetching } =
    useProductsByState(ratingData.state_province);
  const canUpsellPrevent = getProductCanUpsellPrevent(productsByStateData);

  const configPets = usePetPolicyConfig();

  const [isRenewalModalOpen, setIsRenewalModalOpen] = useState(false);

  const hasInsurance = !!pet.policies.length;
  const hasWellness = !!pet.wellness.length;

  /*
   turn the first space in a breed name into a non-breaking space to help with responsiveness
   this works better than most responsiveness approaches (e.g controlling width of breed)
   */

  const items = [
    { title: 'Breed', copy: pet?.breed_name?.replace(/\s/, '&nbsp;') },
    { title: 'Age', copy: formatPetAge(pet?.age) },
  ].filter(item => item.copy); // Filter out items where copy is falsy

  const { active, cancelled, declined, renewal, waiting } = getPolicyConfig(
    pet.policies
  );

  /*
   TODO: look into refactoring RenewalModal to need less prop-drilling - blocked by not wanting to mess up redux / old member center
   PUNKS-1379
  */
  function handleOnDownloadPlan(configPet) {
    const { renewalPlan } = configPet;

    if (hasCustomPlanDoc(renewalPlan.plan)) {
      downloadPlanCa();
    } else {
      downloadPlanCa(configPet.pet.species, renewalPlan.plan.version_number);
    }
  }

  function handleRenewalModalClose() {
    setIsRenewalModalOpen(false);
  }

  const isOnlyWellnessActive = hasWellness && !active.isActive;

  return (
    <div className={styles.root}>
      <MemberCallout type='pet' items={items} className={styles.callout}>
        {cancelled.isDeceased ? (
          <LegalBody className={styles.center}>
            In loving memory{' '}
            <Heart width={21} height={16} className={styles.heart} />
          </LegalBody>
        ) : null}

        {declined.isDeclined ? (
          <LegalBody>
            Plan cancels on <b>{declined.endDate}</b>
          </LegalBody>
        ) : null}

        {waiting.isWaiting ? (
          <LegalBody>
            {waiting.isModelLaw ? 'Illness ' : ''}Waiting Period until{' '}
            <b>{waiting.startDate}</b>
          </LegalBody>
        ) : null}

        {renewal.isRenewal ? (
          <>
            <LegalBody style={{ paddingTop: '4px' }}>
              Plan auto renews <b>{renewal.renewalDate}</b>
            </LegalBody>
            <button
              className={styles.renewal}
              onClick={() => setIsRenewalModalOpen(true)}
            >
              <LegalBody isBold>
                View Renewal Details <ChevronRight width={8} height={14} />
              </LegalBody>
            </button>
          </>
        ) : null}
      </MemberCallout>

      <div className={styles.products}>
        {hasInsurance ? (
          /*
            WELLNESS->INSURANCE UPSELL
            remove conditional, always render PetPolicyOverview
           */
          <PetPolicyOverview
            hasInsurance={hasInsurance}
            documents={getPolicyDocuments(pet.policies)}
            utilization={getPolicyUtilization(pet.policies)}
            dateConfig={getPolicyDates(pet.policies)}
            price={getPolicyPrice(pet.policies, isAnnuallyCharged)}
            classes={{
              root: isOnlyWellnessActive ? styles.order2 : styles.order1,
            }}
            petStatus={getPetStatusCopy(pet.policies)}
            reimbursementPercentage={getPolicyReimbursementPercentage(pet.policies)}
          />
        ) : null}

        {!hasWellness && !shouldShowWellnessUpsell ? (
          pet.plans.length || canUpsellPrevent ? (
            <PetPlanOverview
              hasPlans={!!pet.plans.length}
              petName={pet.name}
              price={getPlanPrice(pet.plans, isAnnuallyCharged)}
              entitlements={getPlanEntitlements(pet.plans)}
              documents={getPlanDocuments(pet.plans)}
              dateConfig={getPlanDates(pet.plans)}
              petStatus={getPetStatusCopy(pet.plans)}
              classes={{ root: styles.order3 }}
            />
          ) : isProductsFetching ? (
            <Spinner />
          ) : null
        ) : null}

        {hasWellness ? (
          <PetWellnessOverview
            petName={pet.name}
            canUpsellInsurance={!hasInsurance}
            price={getWellnessPrice(
              pet.wellness,
              isAnnuallyCharged,
              punks1928WellnessEmployerBenefit
            )}
            entitlements={getWellnessEntitlements(pet.wellness)}
            documents={getWellnessDocuments(pet.wellness)}
            dateConfig={getWellnessDates(
              pet.wellness,
              'Pumpkin Wellness Club',
              punks1928WellnessEmployerBenefit
            )}
            classes={{
              root: isOnlyWellnessActive ? styles.order1 : styles.order2,
            }}
            petStatus={
              pet.wellness.length && !pet.plans.length
                ? getPetStatusCopy(pet.wellness)
                : ''
            }
          />
        ) : null}
      </div>

      {shouldShowInsuranceUpsell ? (
        <div className={styles.insuranceEnrollment}>
          <div className={styles.content}>
            <div className={styles.copySection}>
              <span className={classNames(Typography.h4, styles.sectionTitle)}>
                Add Pet Insurance for {pet.name}
                <img src='/assets/images/iconImages/square_check_mark.png' alt='' />
              </span>

              <LegalBody className={styles.sectionCopy}>
                You're already saving on wellness care. Now help give your pet the
                best care for accidents & illnesses and get cash back on eligible vet
                bills with a Pumpkin Pet Insurance plan.
              </LegalBody>

              <div className={styles.enrollButton}>
                <button
                  className={classNames(ButtonStyles.primary, styles.enrollButton)}
                >
                  Enroll Now
                </button>
              </div>
            </div>
            <div className={styles.imageSection}>
              <img
                src='/assets/images/iconImages/circle-courage-pink.svg'
                alt=''
                className={styles.circleIcon}
              />
              <img
                src='/assets/images/pets-group.png'
                alt=''
                className={styles.petsImage}
              />
            </div>
          </div>
        </div>
      ) : null}

      {shouldShowWellnessUpsell ? <WellnessUpsell species={pet.species} /> : null}

      {isRenewalModalOpen ? (
        <RenewalModal
          isAnnuallyCharged={isAnnuallyCharged}
          configPets={configPets}
          onClose={handleRenewalModalClose}
          onDownloadPolicy={downloadPolicyCa}
          onDownloadPlan={handleOnDownloadPlan}
          transactionFee={transactionFee}
        />
      ) : null}
    </div>
  );
}

export default PetCoverage;
